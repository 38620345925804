const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '',
  title: 'JL.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Joshua Laikowski',
  role: 'Full Stack Engineer',
  description:
    'Passionate software engineer with 5+ years of experience in developing web applications. Skilled at writing cleat and concise code that is easy to maintain and trouble shoot. Expereinced in both small and large teams, as well as independantly. Able to work remote locations or in-office as needed by the company.',
  resume:
    'https://drive.google.com/file/d/1swMpaZ_pdFbJNbFpirQPU8nW-WlTIQbc/view',
  social: {
    linkedin: 'https://www.linkedin.com/in/joshualaikowski/',
    github: 'https://github.com/JLaikows',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Sasha Semeniuk',
    description:
      'A modeling portfolio for actress, singer, songwriter, and entrepenuer Sasha Semeniuk',
    stack: ['Next.JS', 'TypeScript', 'React', 'PostgreSQL', 'Prisma', 'Vercel'],
    sourceCode: 'https://github.com/JLaikows/sashasemenuik',
    livePreview: 'https://sashasemeniuk.com/',
  },
  {
    name: 'Better Mortgage',
    description:
      'Software Engineer III - was responsible for building and maintaining the customer rates page, as well as the decision engine that went behind pre-approval',
    stack: [
      'TypeScript',
      'Python',
      'Go',
      'React',
      'Node.js',
      'AWS',
      'PostgreSQL',
      'HTML',
      'CSS',
      'Confluence',
      'Jira',
    ],
    livePreview: 'https://better.com',
  },
  {
    name: 'Video Corporation of America',
    description:
      'Built and maintained features on their customer web app, as well as provided customer support and on-site calls for audio/visual systems',
    stack: ['Python', 'React', 'Javascript', 'HTML', 'CSS'],
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'AWS',
  'BuildKite (CI/CD)',
  'CI/CD',
  'CSS',
  'Canvas',
  'Confluence',
  'Cypress',
  'Datadog (monitoring)',
  'Ember',
  'Enzyme',
  'Express.js',
  'Figma',
  'Git',
  'Go',
  'Google Maps API',
  'GraphQL',
  'Gulp',
  'HTML',
  'Heroku',
  'JavaScript',
  'Jest',
  'Jest',
  'Jira',
  'Material UI',
  'MongoDB',
  'MySQL',
  'Nextjs',
  'Node.js',
  'Photoshop',
  'PostgreSQL',
  'Python',
  'React',
  'Redis',
  'Redux',
  'Render',
  'Ruby on Rails',
  'Ruby',
  'SASS',
  'SCSS',
  'SQLite',
  'Selenium',
  'Spinnaker',
  'TypeScript',
  'Vercel',
  'Vue.js',
  'Webpack',
  'Websockets',
  'npm/pnpm/yarn',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'joshua@laikowski.com',
  calendly: 'https://calendly.com/joshua-laikowski/30min',
}

export { header, about, projects, skills, contact }
